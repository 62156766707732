<template>
  <!--密码登录-->
  <div>
    <div class="row align-center mt143">	
        <img class="back" src="~images/sj.png" alt="">
        <input class="font28 f-999999 ml25 flex1 input" type="text" v-model="mobile" placeholder="请输入手机号">
    </div>
    <div class="line mt20" style="background-color: #F62341;"></div>
    <div class="row align-center mt60">
        <img class="back" src="~images/mima.png" alt="">
        <input class="font28 f-999999 ml25 flex1 input" type="password" v-model="password" placeholder="请输入密码">
        <div class="ml16 bubiao"><img src="~images/zy.png" alt=""></div>
    </div>
    <div class="line" style="background-color: #F62341;"></div>
    <div class="row flex-end font28 f-666666 mt40"><span>忘记密码？</span></div>
    <div class="row flex-center mt143" @click="loginpsw"><span class="btn-red">登录</span></div>
  </div>
</template>

<script>
// @ is an alias to /src
//import MyHeader from './childCmps/MyHeader'
import {auth_token} from 'network/login'
export default {
  name: 'PswLogin',
  data() {
    return {
     mobile:'',
     password: ''
    }
  },
 
  methods: {
    loginpsw() {
      if(!this.mobile) {
        this.$toast.show('手机号不能为空');
        return;
      }
      if(!this.password) {
        this.$toast.show('密码不能为空');
        return;
      }
      let params = {
        mobile: this.mobile,
        password: this.password
      }
      auth_token(params).then(res=>{
        this.$toast.show('登录成功');
        //1保存用户信息
        let token = 'Token token='+res.data.token+',mobile='+res.data.mobile;
        localStorage.setItem('token',token);
        //返回上一页
        if(this.$route.query.redirect){
          this.$router.push({path:decodeURIComponent(this.$route.query.redirect)});
        }else{
          this.$router.back();
        }
      });
    }
  }
}
</script>
<style  scoped>
.padding{
    padding: 0 .866667rem;
}
.back {
    width: .426667rem;
    height: .426667rem;
}
.underline{
    width:1.066667rem;
    height:.026667rem;
    background-color:rgba(246,35,65,1);
}
.input{
    border: none;
    outline: none;
    cursor: pointer;
}
.bubiao img{
    width: .426667rem;
    height: .426667rem;
}


</style>
