<template>
  <!--tab-->
  <div class="logintab font28 f-1A1A1A mt143">
    <div class="flex1 row flex-center" @click="showLogin(0)"><span :class="{active: currentIndex==0}">密码登录</span></div>
    <div class="flex1 row flex-center" @click="showLogin(1)"><span :class="{active: currentIndex==1}">验证码登录</span></div>
  </div>
</template>

<script>
// @ is an alias to /src
//import MyHeader from './childCmps/MyHeader'
export default {
  name: 'LoginTab',
  data() {
    return {
     currentIndex: 0
    }
  },
 
  methods: {
    showLogin(index){
      this.currentIndex = index;
      this.$emit('showlogin',index);
    }
  }
}
</script>
<style  scoped>
.logintab{
  display: flex;
  box-sizing: border-box;
}
.logintab span{
  padding-bottom: .106667rem;
  height: .533333rem;
}
.active{
  color: var(--color-high-text);
  border-bottom: 2px solid var(--color-high-text);
}
</style>
